export const ROUTES = {
    LANDING: '/',
    HACK_THE_GLOBE: '/hacktheglobe',
    PANEL: '/thoughtleader',
    CURRICULUM: '/curriculum',
    LOCAL_EVENTS: '/localevents',
    TEAM: '/team',
    PODCAST: '/podcast',
    PODCAST_EPISODE: '/podcast/:episodeKey',
    COMMUNITY: '/community',
    SICP: '/socialimpactconsultingprogram',
};

export const EXTERNAL_LINKS = {
    FACEBOOK: 'https://www.facebook.com/globalspark.world',
    INSTAGRAM: 'https://www.instagram.com/global.spark',
    LINKEDIN: 'https://www.linkedin.com/company/global-spark-world/',
    TWITTER: 'https://twitter.com/geweek?lang=en',
    EMAIL: 'contactus@globalspark.world',
    HACKER_DASH: 'https://hack.globalspark.world',
    PANEL_REG: 'http://bit.ly/globalsparkpanel',
    HTG_APP: 'https://form.typeform.com/to/sy0FhvtE',
};

export const SOCIAL_MEDIA_IMAGE = {
    facebook_logo_black: require('../assets/images/socialMediaIcons/facebook_black.svg'),
    instagram_logo_black: require('../assets/images/socialMediaIcons/instagram_black.svg'),
    linkedin_logo_black: require('../assets/images/socialMediaIcons/linkedin_black.svg'),
    twitter_logo_black: require('../assets/images/socialMediaIcons/twitter_black.svg'),
    facebook_logo_white: require('../assets/images/socialMediaIcons/facebook_white.svg'),
    instagram_logo_white: require('../assets/images/socialMediaIcons/instagram_white.svg'),
    linkedin_logo_white: require('../assets/images/socialMediaIcons/linkedin_white.svg'),
    twitter_logo_white: require('../assets/images/socialMediaIcons/twitter_white.svg'),
    logo: require('../assets/images/logo/bright-bg-long.png'),
    logo_short: require('../assets/images/logo/bright-bg-short.png'),
    footer_graphics: require('../assets/images/home/footer-graphics.svg'),
};

export const HOME_IMAGE = {
    curriculum: require('../assets/images/home/curriculum.jpg'),
    htg: require('../assets/images/home/htg.jpg'),
    local_events: require('../assets/images/home/local-events.jpg'),
    speaker_panel: require('../assets/images/home/speaker-panel.jpg'),
    logo: require('../assets/images/home/logo.png'),
    arrow_bl: require('../assets/images/home/arrow_bl.png'),
    arrow_br: require('../assets/images/home/arrow_br.png'),
    arrow_top: require('../assets/images/home/arrow_top.svg'),
    arrow_l: require('../assets/images/home/arrow_l.png'),
    arrow_r: require('../assets/images/home/arrow_r.png'),
    arrow_c: require('../assets/images/home/arrow_c.png'),
    bg: require('../assets/images/home/bg.svg'),
    body_bg: require('../assets/images/home/body-bg.svg'),
    landing: require('../assets/images/home/landing.svg'),
    bg_impact_b: require('../assets/images/home/bg-impact-b.svg'),
    bg_impact_t: require('../assets/images/home/bg-impact-t.svg'),
    globe: require('../assets/images/home/globe.png'),
    landing_bg: require('../assets/images/home/landing_bg.jpeg'),
    impact: require('../assets/images/home/impact.png'),
    join_our_team: require('../assets/images/home/JoinOurTeam.png'),
    icon1: require('../assets/images/home/icon1.png'),
    icon2: require('../assets/images/home/icon2.png'),
    icon3: require('../assets/images/home/icon3.png'),
    objectives: require('../assets/images/home/OurObjectives.png'),
    objectives_arrow: require('../assets/images/home/objectives_arrow.png'),
    objectives_bg: require('../assets/images/home/objectives_bg.png'),
    partner_with_us: require('../assets/images/home/PartnerWithUs.png'),
    what_we_do1: require('../assets/images/home/whatwedo1.png'),
    what_we_do2: require('../assets/images/home/whatwedo2.png'),
    what_we_do3: require('../assets/images/home/whatwedo3.png'),
    what_we_do4: require('../assets/images/home/whatwedo4.png'),
    landing_p: [
        require('../assets/images/home/landing_p1.png'),
        require('../assets/images/home/landing_p2.png'),
        require('../assets/images/home/landing_p3.png'),
    ],
    rotating_globe: require('../assets/images/home/rotating_globe.png'),
    rect_sic: require('../assets/images/home/Rect_SIC.png'),
    rect_thoughtlp: require('../assets/images/home/Rect_ThoughtLP.png'),
    curr_ellipse1: require('../assets/images/home/Ellipse_Curri.png'),
    curr_ellipse2: require('../assets/images/home/Ellipse_Curri2.png'),
    htg_ellipse: require('../assets/images/home/Ellipse_Hacktheglobe.png'),
};

export const PEOPLE_IMAGE = {
    malik_ismail: require('../assets/images/people/Malik_Ismail.png'),
    morris_huang: require('../assets/images/people/Morris_Huang.png'),
    julian_ashworth: require('../assets/images/people/Julian_Ashworth.png'),
    catherine_glossop: require('../assets/images/people/Catherine_Glossop.png'),
    alyf_janmohamed: require('../assets/images/people/Alyf_Janmohamed.png'),
    julia_chae: require('../assets/images/people/Julia_Chae.png'),
    esther_yoo: require('../assets/images/people/Esther_Yoo.png'),
    samantha_unger: require('../assets/images/people/Samantha_Unger.png'),
    ellen_chen: require('../assets/images/people/Ellen_Chen.png'),
    dasha_pushkareva: require('../assets/images/people/Dasha_Pushkareva.png'),
    henry_zhang: require('../assets/images/people/Henry_Zhang.png'),
    phil_lu: require('../assets/images/people/Phil_Lu.png'),
    noah_simao: require('../assets/images/people/Noah_Simao.png'),
    huiwen_zheng: require('../assets/images/people/Huiwen_Zheng.png'),
    andy_jiang: require('../assets/images/people/Andy_Jiang.png'),
    aidan_grenville: require('../assets/images/people/Aidan_Grenville.png'),
    zane_shad: require('../assets/images/people/Zane_Shad.png'),
    mitchell_li: require('../assets/images/people/Mitchell_Li.png'),
    kieran_cyphus: require('../assets/images/people/Kieran_Cyphus.png'),
    jina_yazdanpanah: require('../assets/images/people/Jina_Yaz.png'),
    tina_nia: require('../assets/images/people/Tina_Nia.png'),
    connor_mclennan: require('../assets/images/people/Connor_McLennan.png'),
    felix_yan: require('../assets/images/people/Felix_Yan.png'),
    catherine_chen: require('../assets/images/people/Catherine_Chen.png'),
    ayesha_raza: require('../assets/images/people/Ayesha_Raza.png'),
    brendan_lai: require('../assets/images/people/Brendan_Lai.png'),
    mia_nguyen: require('../assets/images/people/Mia_Nguyen.png'),
    atharv_agrawal: require('../assets/images/people/Atharv_Agrawal.jpg'),
    kailin_hong: require('../assets/images/people/Kailin_Hong.png'),
    afnan_rahman: require('../assets/images/people/Afnan_Rahman.png'),
    tamara_biwott: require('../assets/images/people/Tamara_Biwott.png'),
    vyoma_fadia: require('../assets/images/people/Vyoma_Fadia.png'),
    nicolas_bottger: require('../assets/images/people/Nicolas_Bottger.png'),
    alex_mark: require('../assets/images/people/Alex_Mark.png'),
    henry_xu: require('../assets/images/people/Henry_Xu.png'),
    martina_facchin: require('../assets/images/people/Martina_Facchin.png'),
    sean_xie: require('../assets/images/people/Sean_Xie.png'),
    sarah_chun: require('../assets/images/people/Sarah_Chun.png'),
    ruchi_hamal: require('../assets/images/people/Ruchi_Hamal.png'),
    anna_heer: require('../assets/images/people/Anna_Heer.png'),
    akinori_kimura: require('../assets/images/people/Akinori_Kimura.png'),
    mimi_le: require('../assets/images/people/Mimi_Le.png'),
    min_namgung: require('../assets/images/people/Min_Namgung.png'),
    madison_wong: require('../assets/images/people/Madison_Wong.png'),
    maria_fraser: require('../assets/images/people/Maria_Fraser Semenoff.png'),
    iris_guo: require('../assets/images/people/Iris_Guo.png'),
    hailey_reynolds: require('../assets/images/people/Hailey_Reynolds.png'),
    sandra_yang: require('../assets/images/people/Sandra_Yang.png'),
    annie_zou: require('../assets/images/people/Annie_Zou.png'),
    elliot_lam: require('../assets/images/people/Elliot_Lam.png'),
    alvina_lin: require('../assets/images/people/Alvina_Lin.png'),
    susan_sun: require('../assets/images/people/Susan_Sun.png'),
    henry_mcardle: require('../assets/images/people/Henry_McArdle.png'),
    darren_tan: require('../assets/images/people/Darren_Tan.png'),
    kitan_ademidun: require('../assets/images/people/Kitan_Ademidun.png'),
    mia_vanoirschot: require('../assets/images/people/Mia_Van Oirschot.png'),
    susan_chen: require('../assets/images/people/Susan_Chen.png'),
    chloe_shingay: require('../assets/images/people/Chloe_Shin-Gay.png'),
    karolina_xin: require('../assets/images/people/Karolina_Xin.png'),
    alexa_tumaneng: require('../assets/images/people/Alexa_Tumaneng.png'),
    selina_wan: require('../assets/images/people/Selina_Wan.png'),
    helen_lin: require('../assets/images/people/Helen_Lin.png'),
    zoie_hou: require('../assets/images/people/Zoie_Hou.png'),
    irene_lee: require('../assets/images/people/Irene_Lee.png'),
    shaahana_naufal: require('../assets/images/people/Shaahana_Naufal.png'),
    mymy_tran: require('../assets/images/people/Mymy_Tran.png'),
    ayla_orucevic: require('../assets/images/people/Ayla_Orucevic.png'),
    aashan_meiyappan: require('../assets/images/people/Aashan_Meiyappan.png'),
    yixin_tian: require('../assets/images/people/Yixin_Tian.png'),
    john_kim: require('../assets/images/people/John_Kim.png'),
    roya_rahnejat: require('../assets/images/people/Roya_Rahnejat.png'),
    leila_martin: require('../assets/images/people/Leila_Martin.png'),
    rachel_wong: require('../assets/images/people/Rachel_Wong.png'),
    cassandra_rudenko: require('../assets/images/people/Cassandra_Rudenko.png'),
    mohammadreza_karamsoltani: require('../assets/images/people/Mohammadreza_Karamsoltani.png'),
    sukhpreet_saini: require('../assets/images/people/Sukhpreet_Saini.png'),
    chloe_tam: require('../assets/images/people/Chloe_Tam.png'),
    akino_watanabe: require('../assets/images/people/Akino_Watanabe.png'),
    ammar_siddiqui: require('../assets/images/people/Ammar_Siddiqui.png'),
    kirsten_davis: require('../assets/images/people/Kirsten_Davis.png'),
    teresa_vu: require('../assets/images/people/Teresa_Vu.png'),
    lewis_liu: require('../assets/images/people/Lewis_Liu.png'),
    sabrina_cao: require('../assets/images/people/Sabrina_Cao.png'),
    annabeth_lin: require('../assets/images/people/Annabeth_Lin.png'),
    zoe_fong: require('../assets/images/people/Zoe_Fong.png'),
    nika_asgari: require('../assets/images/people/Nika_Asgari.png'),
    saad_shan: require('../assets/images/people/Saad_Shan.png'),
    jason_amri: require('../assets/images/people/Jason_Amri.jpeg'),
    rahul_lohana: require('../assets/images/people/Rahul.jpg'),
    nikasha_chauhan: require('../assets/images/people/Nikasha.jfif'),
    isha_godara: require('../assets/images/people/Isha_Godara.jpg'),
    kimberly_watada: require('../assets/images/people/Kimberly_Watada.jpg'),
    katarina_chiam: require('../assets/images/people/Katarina_Chiam.png'),
    amelia_jiang: require('../assets/images/people/Amelia_Jiang.jpg'),
    gallant_tang: require('../assets/images/people/Gallant.jpg'),
    alexa_nguyen: require('../assets/images/people/Alexa_Nguyen.jpg'),
    sheree_zhang: require('../assets/images/people/Sheree_Zhang.jpg'),
    jenna_ramji: require('../assets/images/people/Jenna_Ramji.png'),
    sami_el_sabri: require('../assets/images/people/Sami_el_Sabri.png'),
    ana_meletti: require('../assets/images/people/Ana_Meletti.png'),
    julie_moysiuk: require('../assets/images/people/Julie_Moysiuk.png'),
    ben_mucsi: require('../assets/images/people/Ben_Mucsi.png'),
    katherine_bancroft: require('../assets/images/people/Katherine_Bancroft.png'),
    yingxue_xiang: require('../assets/images/people/YingXue_Xiang.png'),
    ashna_jain: require('../assets/images/people/Ashna_Jain.jpg'),
    vidur_channa: require('../assets/images/people/Vidur_Channa.png'),
    stephen_chankov: require('../assets/images/people/Stephen_Chankov.png'),
    emily_dong: require('../assets/images/people/Emily_Dong.png'),
    anunay_kumar: require('../assets/images/people/Anunay_Kumar.png'),
    mira_merchant: require('../assets/images/people/Mira_Merchant.png'),
    suvaena_laventhiran: require('../assets/images/people/Suvaena_Laventhiran.png'),
    alice_zhou: require('../assets/images/people/Alice_Zhou.png'),
    shrinjay_mukherjee: require('../assets/images/people/Shrinjay_Mukherjee.png'),
    andrew_xue: require('../assets/images/people/Andrew_Xue.png'),
    xinyi_fu: require('../assets/images/people/Xinyi_Fu.png'),
    vyomesh_iyengar: require('../assets/images/people/Vyomesh_Iyengar.png'),
    jayden_jung: require('../assets/images/people/Jayden_Jung.jpg'),
    grace_pu: require('../assets/images/people/Grace_Pu.png'),
    allen_lu: require('../assets/images/people/Allen_Lu.png'),
    levi_sanchez: require('../assets/images/people/Levi_Sanchez.png'),
    christopher_chen: require('../assets/images/people/Christopher_Chen.png'),
    faith_king: require('../assets/images/people/Faith_King.jpeg'),
    kerry_liu: require('../assets/images/people/Kerry_Liu.jpg'),
    // new 2022 intake
    maharshi_desai: require('../assets/images/people/Maharshi_Desai.png'),
    abigail_ilaka: require('../assets/images/people/Abigail_Ilaka.jpeg'),
    ahsan_eslami: require('../assets/images/people/Ahsan_Eslami.jpg'),
    alvin_roh: require('../assets/images/people/Alvin_Roh.jpg'),
    angel_yu: require('../assets/images/people/Angel_Yu.png'),
    archie_ju: require('../assets/images/people/Archie_Ju.jpg'),
    ash_verma: require('../assets/images/people/Ashutosh_Verma.jpg'),
    becca_zhu: require('../assets/images/people/Becca_Zhu.JPG'),
    crystal_lee: require('../assets/images/people/Crystal_Lee.jpg'),
    daniel_kim: require('../assets/images/people/Daniel_Kim.jpg'),
    deep_lekha: require('../assets/images/people/Deep_Leekha.png'),
    donna_yang: require('../assets/images/people/Donna_Yang.png'),
    jesus_palenzuela: require('../assets/images/people/Jesus_Palenzuela.jpg'),
    kai_chan: require('../assets/images/people/Kai_Chan.jpg'),
    khadija_shuaib: require('../assets/images/people/Khadija_Shuaib.jpeg'),
    krish_bandivdekar: require('../assets/images/people/Krish_Bandivdekar.png'),
    kushagra_jindal: require('../assets/images/people/Kushagra_Jindal.png'),
    levina_ira: require('../assets/images/people/Levina_Ira.png'),
    lili_lantos: require('../assets/images/people/Lili_Lantos.png'),
    mahad_zaryab: require('../assets/images/people/Mahad_Zaryab.jpg'),
    mate_baltay: require('../assets/images/people/Mate_Baltay.png'),
    niklas_tecklenburg: require('../assets/images/people/Niklas_Tecklenburg.jpg'),
    patrick_luo: require('../assets/images/people/Patrick_Luo.jpeg'),
    pritika_vij: require('../assets/images/people/Pritika_Vij.png'),
    samuel_mue: require('../assets/images/people/Samuel_Mue.png'),
    shirley_mu: require('../assets/images/people/Shirley_Mu.png'),
    stephanie_doan: require('../assets/images/people/Stephanie_Doan.jpg'),
    samra_shafqat: require('../assets/images/people/Samra_Shafqat.jpg'),
    mohamed_alkawaja: require('../assets/images/people/Mohamed_Alkawaja.jpeg'),
    ahri_wu: require('../assets/images/people/Ahri_Wu.png'),
    trizia_singian: require('../assets/images/people/Trizia_Singian.JPG'),
    radin_hakimjavadi: require('../assets/images/people/Radin_Hakimjavadi.png'),
    rohail_khan: require('../assets/images/people/Rohail_Khan.jpg'),
    rose_nguyen: require('../assets/images/people/Rose_Nguyen.jpg'),
    molly_borritt: require('../assets/images/people/Molly_Borritt.jpeg'),
    paavnee_misra: require('../assets/images/people/Paavnee_Misra.jpg'),
    judy_gong: require('../assets/images/people/Judy_Gong.jpg'),
    nicole_lin: require('../assets/images/people/Nicole_Lin.jpg'),
    melissa_lin: require('../assets/images/people/Melissa_Lin.png'),
    emily_gazo: require('../assets/images/people/Emily_Gazo.png'),
    joyce_wangsa: require('../assets/images/people/Joyce_Wangsa.jpg'),
    nathan_somsaard: require('../assets/images/people/Nathan_Somsaard.jpeg'),
    amanda_rattle: require('../assets/images/people/Amanda_Rattle.jpg'),
    jennifer_zhu: require('../assets/images/people/Jennifer_Zhu.jpeg'),
    brent_urbanski: require('../assets/images/people/Brent_Urbanski.jpg'),
    isabel_lew: require('../assets/images/people/Isabel_Lew.jpg'),
    zeinab_chamas: require('../assets/images/people/Zeinab_Chamas.JPG'),
    armin_kusmic: require('../assets/images/people/Armin_Kusmic.png'),
    silin_wei: require('../assets/images/people/Silin_Wei.png'),
    achint_singh: require('../assets/images/people/Achint_Singh.png'),
    // new 2023 intake
    mehdia_lodhi: require('../assets/images/people/Mehdia_Lodhi.jpg'),
    youngwon_kim: require('../assets/images/people/Youngwon_Kim.jpeg'),
    vedant_iyer: require('../assets/images/people/Vedant_Iyer.png'),
    vivian_zhong: require('../assets/images/people/Vivian_Zhong.jpg'),
    nicole_simiyu: require('../assets/images/people/Nicole_Simiyu.jpg'),
    hannah_mahr: require('../assets/images/people/Hannah_Mahr.png'),
    daniel_wang: require('../assets/images/people/Daniel_Wang.jpg'),
    asima_kidwai: require('../assets/images/people/Asima_Kidwai.jpg'),
    selena_yang: require('../assets/images/people/Selena_Yang.jpg'),
    belinda_zhao: require('../assets/images/people/Belinda_Zhao.png'),
    daniel_wang2: require('../assets/images/people/Daniel_Wang2.jpg'),
    wei_tung_ling: require('../assets/images/people/Wei-Tung_Ling.jpg'),
    abner_khuzwayo: require('../assets/images/people/Abner_Khuzwayo.jpg'),
    samantha_vu: require('../assets/images/people/Samantha_Vu.png'),
    seline_kawpeng: require('../assets/images/people/Seline_Kawpeng.png'),
    default: require('../assets/images/people/default.png'),
    leanna_lui: require('../assets/images/people/Leanna_Lui.png'),
    shirley_zhong: require('../assets/images/people/Shirley_Zhong.jpg'),
    gia_khanh_luong: require('../assets/images/people/Gia_Khanh.jpg'),
    george_chan: require('../assets/images/people/George_Chan.png'),
    leo_zhang: require('../assets/images/people/Leonardo_Zhang.jpeg'),
    helena_jovic: require('../assets/images/people/Helena_Jovic.png'),
    izora_kayumova: require('../assets/images/people/Izora_Kayumova.png'),
    rebeca_alarcon: require('../assets/images/people/Rebeca_Alarcon.png'),
    minyi_wang: require('../assets/images/people/Minyi_Wang.jpg'),
    fares_abdellatif: require('../assets/images/people/Fares_Abdellatif.jpg'),
    jasmin_virdee: require('../assets/images/people/Jasmine_Virdee.png'),
    ella_tetrault: require('../assets/images/people/Ella_Tetrault.jpg'),
    nicholas_palacio: require('../assets/images/people/Nicholas_Palacio.png'),
    catherine_kawpeng: require('../assets/images/people/Catherine_Kawpeng.png'),
    edward_he: require('../assets/images/people/Edward_He.png'),
    joanne_yuan: require('../assets/images/people/Joanne_Yuan.png'),
    billy_shi: require('../assets/images/people/Billy_Shi.png'),
    shaba_khan: require('../assets/images/people/Shaba_Khan.png'),
    ekam_sidu: require('../assets/images/people/Ekam_Sidhu.png'),
    jovita_felita: require('../assets/images/people/Jovita_Felita.JPG'),
    alison_chong: require('../assets/images/people/Alison_Chong.png'),
    joyce_xuan: require('../assets/images/people/Joyce_Xuan.png'),
    jason_le: require('../assets/images/people/Jason_Le.png'),
    daniel_musau: require('../assets/images/people/Daniel_Musau.JPG'),
    gerry_chen: require('../assets/images/people/Gerry_Chen.png'),
    lance_madarang: require('../assets/images/people/Lance_Madarang.jpg'),
    david_dimalanta: require('../assets/images/people/David_Dimalanta.jpg'),
    rena_doan: require('../assets/images/people/Rena_Doan.png'),
    martina_bravo: require('../assets/images/people/Martina_Bravo.png'),
    blythe_huang: require('../assets/images/people/Blythe_Huang.jpeg'),
    rickie_huang: require('../assets/images/people/Rickie_Huang.jpg'),
    isabelle_gan: require('../assets/images/people/Isabelle_Gan.png'),
    jerry_wu: require('../assets/images/people/Jerry_Wu.png'),
    nicole_nanzai: require('../assets/images/people/Nicole_Nanzai.jpg'),
    dhruv_leekha: require('../assets/images/people/Dhruv_Leekha.png'),
    ashley_kahaviza: require('../assets/images/people/Ashley_Kahaviza.jpg'),
    shaun_sunil: require('../assets/images/people/Shaun_Sunil.png'),
    khanh_tran: require('../assets/images/people/Khanh_Tran.jpg'),
    jessica_oh: require('../assets/images/people/Jessica_Oh.png'),
    andrew_sun: require('../assets/images/people/Andrew_Sun.png'),
    joy_lin: require('../assets/images/people/Joy_Lin.jpg'),
    misheel_batkhuu: require('../assets/images/people/Misheel_Batkhuu.png'),
    lalou_abdie: require('../assets/images/people/Lalou_Abdie.png'),
    kate_banting: require('../assets/images/people/Kate_Banting.png'),
    christopher_yip: require('../assets/images/people/Christopher_Yip.png'),
    shajan_kamalanathan: require('../assets/images/people/Shajan_Kamalanathan.png'),
    // new 2024 intake
    aania_raheem: require('../assets/images/people/Aania_Raheem.png'),
    alexander_yuyitung: require('../assets/images/people/Alexander_Yuyitung.png'),
    ally_yuen: require('../assets/images/people/Ally_Yuen.png'),
    amyna_ismail: require('../assets/images/people/Amyna_Ismail.png'),
    ananya_tandon: require('../assets/images/people/Ananya_Tandon.png'),
    andrew_song: require('../assets/images/people/Andrew_Song.png'),
    anthony_wang: require('../assets/images/people/Anthony_Wang.png'),
    areeya_hanvi: require('../assets/images/people/Areeya_Hanvichit.png'),
    ariza_hossain: require('../assets/images/people/Ariza_Hossain.png'),
    audrey_gong: require('../assets/images/people/Audrey_Gong.png'),
    baihe_chen: require('../assets/images/people/Baihe_Chen.png'),
    chaewon_kang: require('../assets/images/people/Chaewon_Kang.png'),
    daniel_tran: require('../assets/images/people/Daniel_Tran.png'),
    dante_dorr: require('../assets/images/people/Dante_Dorr.png'),
    devashi_gupta: require('../assets/images/people/Devashi_Gupta.png'),
    devika_tomar: require('../assets/images/people/Devika_Tomar.png'),
    dylan_otoole: require('../assets/images/people/Dylan_OToole.png'),
    emma_he: require('../assets/images/people/Emma_He.png'),
    emmeline_handojo: require('../assets/images/people/Emmeline_Handojo.png'),
    fatimah_nadir: require('../assets/images/people/Fatimah_Nadir.png'),
    georgie_burr: require('../assets/images/people/Georgie_Burr.jpg'),
    gregory_young: require('../assets/images/people/Gregory_Young.png'),
    hasna_hafidzah: require('../assets/images/people/Hasna_Hafidzah.png'),
    humdaan_javed: require('../assets/images/people/Humdaan_Javed.png'),
    ishleen_wadehra: require('../assets/images/people/Ishleen_Wadehra.png'),
    jeffrey_xuzhang: require('../assets/images/people/Jeffrey_Xuzhang.png'),
    jerry_lam: require('../assets/images/people/Jerry_Lam.png'),
    jia_yi_deng: require('../assets/images/people/Jia_Yi_Deng.png'),
    kate_chang: require('../assets/images/people/Kate_Chang.png'),
    kevin_cui: require('../assets/images/people/Kevin_Cui.png'),
    laurel_dong: require('../assets/images/people/Laurel_Dong.png'),
    mahrosh_zafar: require('../assets/images/people/Mahrosh_Zafar.png'),
    melody_nguyen: require('../assets/images/people/Melody_Nguyen.png'),
    meriem_mehri: require('../assets/images/people/Meriem_Mehri.png'),
    natasha_pereira: require('../assets/images/people/Natasha_Pereira.png'),
    neema_mkenda: require('../assets/images/people/Neema_Mkenda.png'),
    nivriti_bajwa: require('../assets/images/people/Nivriti_Bajwa.png'),
    prisha_tharwani: require('../assets/images/people/Prisha_Tharwani.png'),
    ray_fang: require('../assets/images/people/Ray_Fang.png'),
    rishika_jain: require('../assets/images/people/Rishika_Jain.png'),
    shashwat_parikh: require('../assets/images/people/Shashwat_Parikh.png'),
    sophie_yau: require('../assets/images/people/Sophie_Yau.png'),
    vanessa_tsui: require('../assets/images/people/Vanessa_Tsui.png'),
    victoria_lobo: require('../assets/images/people/Victoria_Lobo.png'),
    zainab_goriawala: require('../assets/images/people/Zainab_Goriawala.png'),
    zhi_ling_jiang: require('../assets/images/people/Zhi_Ling_Jiang.png'),
};

export const HTG_IMAGE = {
    landing: require('../assets/images/htg/landing1.jpg'),
    bg: require('../assets/images/htg/bg.svg'),
    htg_2020: require('../assets/images/htg/htg-2020.jpg'),
    htg_design: require('../assets/images/htg/htg-design.jpg'),
    peoplechoice: require('../assets/images/htg/peoplechoice.jpg'),
    runnerup: require('../assets/images/htg/runnerup.jpg'),
    winner: require('../assets/images/htg/winner.jpg'),
    peoplechoice2021: require('../assets/images/htg/DermaSpect.jpg'),
    runnerup2021: require('../assets/images/htg/Argent.jpg'),
    winner2021: require('../assets/images/htg/reusableEats.jpg'),
    third2023: require('../assets/images/htg/CheckMate.jpg'),
    second2023: require('../assets/images/htg/AdaptEd.jpg'),
    first2023: require('../assets/images/htg/bagSHARE.jpg'),
    shoes: require('../assets/images/htg/shoes.png'),
    data: require('../assets/images/htg/data.png'),
    hands: require('../assets/images/htg/hands.png'),
    lightbulb: require('../assets/images/htg/lightbulb.png'),
    two_week: require('../assets/images/htg/two-week.jpg'),
    multidisciplinary: require('../assets/images/htg/multidisciplinary.jpg'),
    mentorship: require('../assets/images/htg/mentorship.jpg'),
    ngo: require('../assets/images/htg/ngo.jpg'),
    climate: require('../assets/images/htg/climate.svg'),
    economic: require('../assets/images/htg/economic.svg'),
    health: require('../assets/images/htg/health.svg'),
    society: require('../assets/images/htg/society.svg'),
    speaker1: require('../assets/images/htg/dr_peter_singer.jpg'),
    speaker2: require('../assets/images/htg/bas_sudmeijer.jpg'),
    htg_logo: require('../assets/images/htg/htg-logo.svg'),
    testimonial1: require('../assets/images/htg/testimonial1.png'),
    testimonial2: require('../assets/images/htg/testimonial2.png'),
    testimonial3: require('../assets/images/htg/testimonial3.png'),
    schedule1: require('../assets/images/htg/schedule1.svg'),
    schedule2: require('../assets/images/htg/schedule2.svg'),
    schedule3: require('../assets/images/htg/schedule3.svg'),
    bcgMentoring: require('../assets/images/htg/bcgMentoring.jpg'),
    kickoff: require('../assets/images/htg/kickoff.jpg'),
    firstPrize2023: require('../assets/images/htg/firstPrize2023.jpg'),
};

export const SPEAKER_IMAGE = {
    landing: require('../assets/images/speaker/landing.jpg'),
    sarah: require('../assets/images/speaker/sarah.jpg'),
    marieme: require('../assets/images/speaker/marieme.jpg'),
    omar: require('../assets/images/speaker/omar.png'),
    joe: require('../assets/images/speaker/joe.png'),
    mark: require('../assets/images/speaker/mark.jpg'),
    carla: require('../assets/images/speaker/carla.png'),
    jessie: require('../assets/images/speaker/jessie.png'),
    laura: require('../assets/images/speaker/laura.png'),
    janice: require('../assets/images/speaker/janice.png'),
    tim: require('../assets/images/speaker/tim.png'),
    andrew: require('../assets/images/speaker/andrew.png'),
    maureen: require('../assets/images/speaker/maureen.png'),
    jim: require('../assets/images/speaker/jim.png'),
    natasha: require('../assets/images/speaker/natasha.png'),
    page: require('../assets/images/speaker/page.png'),
    matt: require('../assets/images/speaker/matt.jpg'),
    donnel: require('../assets/images/speaker/donnel.png'),
    tina: require('../assets/images/speaker/tina.png'),
};

export const EVENTS_IMAGE = {
    landing: require('../assets/images/events/landing.jpg'),
    design_competition: require('../assets/images/events/design-competition.jpg'),
    public_events: require('../assets/images/events/public-events.jpg'),
    speaker_series: require('../assets/images/events/speaker-series.jpg'),
};

export const CURRICULUM_IMAGE = {
    landing: require('../assets/images/curriculum/landing.jpg'),
    covid: require('../assets/images/curriculum/covid.jpeg'),
    climate: require('../assets/images/curriculum/climate.jpeg'),
    chemeng: require('../assets/images/curriculum/chemeng.jpeg'),
    electricity: require('../assets/images/curriculum/electricity.jpg'),
    vaccine: require('../assets/images/curriculum/vaccine.jpeg'),
    bulb: require('../assets/images/curriculum/bulb.png'),
    screen: require('../assets/images/curriculum/screen.png'),
    search: require('../assets/images/curriculum/search.png'),
    graph1: require('../assets/images/curriculum/graph1.svg'),
    graph2: require('../assets/images/curriculum/graph2.png'),
    graph3: require('../assets/images/curriculum/graph3.png'),
    graph4: require('../assets/images/curriculum/graph4.png'),
};

export const SPONSORS_IMAGE = {
    stc: require('../assets/images/sponsors/stc.png'),
    bmo: require('../assets/images/sponsors/bmo.png'),
    mars: require('../assets/images/sponsors/mars.png'),
    bcg: require('../assets/images/sponsors/bcg.png'),
    otpp: require('../assets/images/sponsors/otpp.png'),
    uw: require('../assets/images/sponsors/uw.png'),
    ewb: require('../assets/images/sponsors/ewb.png'),
    western: require('../assets/images/sponsors/western.png'),
    ibm: require('../assets/images/sponsors/ibm.png'),
    gates: require('../assets/images/sponsors/gates.png'),
    gu: require('../assets/images/sponsors/gu.png'),
    clearbanc: require('../assets/images/sponsors/clearbanc.png'),
    uber_eats: require('../assets/images/sponsors/uber-eats.png'),
    growing_north: require('../assets/images/sponsors/growing-north.jpg'),
    agakhan: require('../assets/images/sponsors/agakhan.png'),
    sea: require('../assets/images/sponsors/sea.png'),
    soc: require('../assets/images/sponsors/soc.png'),
    uoft: require('../assets/images/sponsors/uoft.png'),
    ubc: require('../assets/images/sponsors/ubc.png'),
    celo: require('../assets/images/sponsors/celo.png'),
    leap: require('../assets/images/sponsors/leap.png'),
    google: require('../assets/images/sponsors/google.png'),
    wwf: require('../assets/images/sponsors/wwf.png'),
    wise: require('../assets/images/sponsors/wise.png'),
    cfcf: require('../assets/images/sponsors/cfcf.png'),
    nsbe: require('../assets/images/sponsors/nsbe.png'),
    munk: require('../assets/images/sponsors/munk.png'),
    degree_consulting: require('../assets/images/sponsors/180.png'),
    yncn: require('../assets/images/sponsors/yncn.png'),
    ieee: require('../assets/images/sponsors/ieee.png'),
    blueprint: require('../assets/images/sponsors/blueprint.png'),
    uwpm: require('../assets/images/sponsors/uwpm.png'),
    pbsn: require('../assets/images/sponsors/pbsn.png'),
    wecca: require('../assets/images/sponsors/wecca.png'),
    wds: require('../assets/images/sponsors/wds.png'),
    wics: require('../assets/images/sponsors/wics.png'),
    uwimpact: require('../assets/images/sponsors/uwimpact.png'),
    uwfossil: require('../assets/images/sponsors/uwfossil.png'),
    uwclimate: require('../assets/images/sponsors/uwclimate.png'),
    sci: require('../assets/images/sponsors/sci.png'),
    bag: require('../assets/images/sponsors/bag.png'),
    bcgcfca: require('../assets/images/sponsors/bcgcfca.jpg'),
    enactus: require('../assets/images/sponsors/enactus.png'),
    enactus_hack: require('../assets/images/sponsors/enactus_hack.png'),
    uweng: require('../assets/images/sponsors/uweng.png'),
    wfp: require('../assets/images/sponsors/wfp.png'),
    hfh_uw: require('../assets/images/sponsors/hfh-uw.png'),
    gcc: require('../assets/images/sponsors/gcc.jpg'),
    mcmaster: require('../assets/images/sponsors/mcmaster.png'),
    td: require('../assets/images/sponsors/td.png'),
    flipp: require('../assets/images/sponsors/flipp.png'),
    darwin: require('../assets/images/sponsors/darwin.png'),
    ripple: require('../assets/images/sponsors/ripple.png'),
    voiceflow: require('../assets/images/sponsors/voiceflow.png'),
    thinkdata: require('../assets/images/sponsors/thinkdata.png'),
    cyclica: require('../assets/images/sponsors/cyclica.png'),
    united: require('../assets/images/sponsors/united.jpg'),
    rumie: require('../assets/images/sponsors/rumie.png'),
    bcch: require('../assets/images/sponsors/bcch.jpg'),
    global_medic: require('../assets/images/sponsors/global-medic.png'),
    nem: require('../assets/images/sponsors/nem.png'),
    mastercard: require('../assets/images/sponsors/mastercard.jpg'),
    grameen: require('../assets/images/sponsors/grameen.png'),
    loi: require('../assets/images/sponsors/loi.png'),
    gp: require('../assets/images/sponsors/gp.jpg'),
    ef: require('../assets/images/sponsors/ef.png'),
    reach_alliance: require('../assets/images/sponsors/reach_alliance.png'),
    eia: require('../assets/images/sponsors/eia.jpg'),
    science_rendezvous: require('../assets/images/sponsors/science_rendezvous.png'),
    hh_debates: require('../assets/images/sponsors/hh_debates.jpg'),
    uwo_debate: require('../assets/images/sponsors/uwo_debate.png'),
    uwux: require('../assets/images/sponsors/uwux.png'),
    sdg: require('../assets/images/sponsors/sdg.png'),
    impact_alliance: require('../assets/images/sponsors/impact_alliance.jpg'),
    food_kid: require('../assets/images/sponsors/food_kid.png'),
    ic3: require('../assets/images/sponsors/ic3.jpg'),
    uw_wise: require('../assets/images/sponsors/uw_wise.jpg'),
    jaj: require('../assets/images/sponsors/j&j.png'),
};

export const PAST_LOCAL_EVENTS_IMAGE = {
    uoft_vaccine: require('../assets/images/localEvents/uoft_vaccination_conundrum.jpg'),
    uoft_yncn: require('../assets/images/localEvents/uoft_yncn_fireside.jpg'),
    uoft_innovative: require('../assets/images/localEvents/uoft_innovative.jpg'),
    uoft_reach: require('../assets/images/localEvents/uoft_reach.jpg'),
    uw_design: require('../assets/images/localEvents/uw_design.jpg'),
    uw_products: require('../assets/images/localEvents/uw_products.jpg'),
    uw_women_tech: require('../assets/images/localEvents/uw_women_tech.jpg'),
    uw_health: require('../assets/images/localEvents/uw_health.jpg'),
    uw_eng_competition: require('../assets/images/localEvents/uw_eng_competition.jpg'),
    uw_geo: require('../assets/images/localEvents/uw_geo.jpg'),
};

export const PODCAST_IMAGE = {
    podcast_logo_square: require('../assets/images/podcast/podcast_logo_square.png'),
    podcast_logo_round: require('../assets/images/podcast/podcast_logo_round.png'),
    apple_podcast: require('../assets/images/podcast/apple_podcast.png'),
    google_podcast: require('../assets/images/podcast/google_podcast.png'),
    spotify_podcast: require('../assets/images/podcast/spotify_podcast.png'),
    youtube_podcast: require('../assets/images/podcast/youtube_podcast.jpg'),
};

export const COMMUNITY_IMAGE = {
    community_roundtable: require('../assets/images/community/community.svg'),
};

export const SICP_IMAGE = {
    sicp_intro_cartoon: require('../assets/images/sicp/sicp_intro_cartoon.png'),
    sicp_intro_background: require('../assets/images/sicp/sicp_intro_background.png'),
    rocket: require('../assets/images/sicp/rocket.png'),
    calendar: require('../assets/images/sicp/calendar.png'),
    trophy: require('../assets/images/sicp/trophy.png'),
    timeline_bar: require('../assets/images/sicp/timeline_bar.png'),
    abigail_okyere: require('../assets/images/sicp/abigail_okyere.png'),
    heather_mactaggart: require('../assets/images/sicp/heather_mactaggart.png'),
};
